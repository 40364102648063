<template>
  <div v-show="show" :style="'height:' + window.height + 'px'" class="ma-n3">
    <iframe
      :src="source"
      @load="onload"
      frameborder="0"
      style="overflow: hidden; height: 100%; width: 100%"
      height="100%"
      width="100%"
      id="helpDoc"
    >
    </iframe>
  </div>
</template>
<script>
export default {
  // components:{iframe},
  props: ["result"],
  data() {
    return {
      show: false,
      source: "",
      window: {
        width: 0,
        height: 0,
      },
    };
  },

  created() {
    document.title = "Help";
    let q = "";
    for (let [index, value] of Object.entries(this.$route.query)) {
      q += index + "=" + value + "&";
    }

    this.source = "/docs.php?" + q;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    onload(props) {
      let target = props.target.contentWindow.location.href;
      target = target.replace("docs.php", "").replace("&extension=html", "");
      //console.log("loaded", target);
      window.history.pushState("help", "Help", target);
      this.show = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 120;
    },
  },
};
</script>
<style >
html {
  overflow: hidden !important;
}
</style>

